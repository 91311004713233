@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css");

// ::-webkit-scrollbar {
//   width: 4px;
//   height: 4px;
//   overflow-y: scroll;
//   background: #ffff;
//   box-shadow: inset 0 0 4px #707070;
// }

// ::-webkit-scrollbar-thumb {
//   background: #adb5bd;
//   border-radius: 0px;
// }
.dataTables_paginate {
       margin-top: 0 !important;
       width: 100% !important;
       float: left;
}

.createRole.dataTables_wrapper div.dataTables_info {
       padding-top: 0.85em;
       display: none;
}

div.table-responsive > div.dataTables_wrapper > div.row:nth-of-type(3) {
       // min-height: 86%;
       margin-top: auto;
}

#dataTableUserAvecRole_wrapper {
       height: 100%;
       position: relative;
       display: flex;
       flex-direction: column;
}

#dataTableUserAvecRole2_wrapper {
       height: 100%;
       position: relative;
       display: flex;
       flex-direction: column;
}

#dataTableAddRolePermissionList_wrapper {
       height: 100%;
       position: relative;
       display: flex;
       flex-direction: column;
}

#dataTableAddRoleUsersList_wrapper {
       height: 100%;
       position: relative;
       display: flex;
       flex-direction: column;
}
